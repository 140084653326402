import BookList from './list'
import BookCreate from './create'
import BookEdit from './edit'

const Book = {
  list: BookList,
  create: BookCreate,
  edit: BookEdit,
}
export default Book
