import * as React from 'react'
import { Create, SimpleForm, TextInput, DateInput, BooleanInput, SelectInput } from 'react-admin'

const MarqueeCreate = (props) => {
  return (
    <Create redirect='list' title='建立公告' {...props}>
      <SimpleForm>
        <TextInput source='content' label='公告內容' required />
        <TextInput source='category' label='公告類型' required />
        <SelectInput
          source='location'
          label='公告位置'
          required
          choices={[{ id: 'header', name: 'Header' }]}
        />
        <DateInput source='expiredDate' label='有效期限' required />
        <BooleanInput label='啟用狀態' source='isActive' />
      </SimpleForm>
    </Create>
  )
}

export default MarqueeCreate
