import {
  ArrayInput,
  Edit,
  FileField,
  FileInput,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin'

const MyFileInput = () => {
  const record = useRecordContext()
  const id = record.id
  const name = record.file
  return (
    <FileInput
      format={(value) => {
        if (typeof value === 'string') {
          // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
          return {
            src: `${process.env.REACT_APP_CLOUDFRONT}/landscaping/${id}/${value}`,
            name: name,
          }
        } else {
          // Else a new image is selected which results in a value object already having a preview link under the url key

          return { ...value, name: value.title }
        }
      }}
      source='file'
      label='造景檔案'
      validate={required()}
    >
      <FileField source='src' title='name' />
    </FileInput>
  )
}

const LandscapingEdit = () => {
  return (
    <Edit title='景觀編輯'>
      <SimpleForm>
        <TextInput source='landscapingName' label='造景名稱' required />
        <MyFileInput />
        <ArrayInput source='position' label='造景位置' required>
          <SimpleFormIterator inline>
            <NumberInput source='positionX' label='X座標' helperText={false} required />
            <NumberInput source='positionY' label='Y座標' helperText={false} required />
            <NumberInput source='positionZ' label='Z座標' helperText={false} required />
            <NumberInput source='direction' label='旋轉角度' helperText={false} required />
            <NumberInput source='size' label='造景大小' required />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default LandscapingEdit
