import {
  AutocompleteArrayInput,
  Edit,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  TabbedForm,
  TextInput,
} from 'react-admin'
import { useParams } from 'react-router-dom'

const formatImg = (value, id) => {
  if (!value || typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { src: `${process.env.REACT_APP_CLOUDFRONT}/book/${id}/${value}` }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

const formatMedia = (value, id) => {
  console.log(value)
  return value.map((item) => {
    if (item.rawFile) {
      console.log(item)
      return {
        ...item,
        name: item.title,
        src: item.src,
      }
    } else {
      return {
        ...item,
        name: item.name,
        src: `${process.env.REACT_APP_CLOUDFRONT}/book/${id}/${item}`,
      }
    }
  })
}

const BookEdit = () => {
  const { id } = useParams()
  return (
    <Edit>
      <TabbedForm>
        <FormTab label='書本'>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <TextInput label='書名' source='bookName' style={{ width: '31%' }} />
              <TextInput label='作者' source='author' style={{ width: '31%' }} />
              <TextInput label='出版商' source='publisher' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='X座標' source='positionX' style={{ width: '31%' }} />
              <NumberInput label='Y座標' source='positionY' style={{ width: '31%' }} />
              <NumberInput label='Z座標' source='positionZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='書本大小X' source='bookSizeX' style={{ width: '31%' }} />
              <NumberInput label='書本大小Y' source='bookSizeY' style={{ width: '31%' }} />
              <NumberInput label='書本大小Z' source='bookSizeZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='旋轉X' source='rotationX' style={{ width: '31%' }} />
              <NumberInput label='旋轉Y' source='rotationY' style={{ width: '31%' }} />
              <NumberInput label='旋轉Z' source='rotationZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <TextInput label='顏色' source='bookColor' style={{ width: '48%' }} />
              <TextInput label='贊助商' source='supplier' style={{ width: '48%' }} />
            </div>
            <ImageInput format={(value) => formatImg(value, id)} label='封面' source='cover'>
              <ImageField source='src' title='book image' />
            </ImageInput>

            <FileInput
              label='書本媒體'
              source='media'
              multiple
              format={(value) => formatMedia(value, id)}
            >
              <FileField source='src' title='name' src='src' />
            </FileInput>
            <TextInput source='mediaLink' multiline />
            <TextInput source='storeLink' multiline />

            <AutocompleteArrayInput
              source='category'
              choices={[
                { id: 'comics', name: '漫畫圖文' },
                { id: 'lightNovel', name: '輕小說' },
                { id: 'humanitiesAndHistoryAndPhilosophy', name: '人文史哲' },
                { id: 'artDesign', name: '藝術設計' },
                { id: 'medicalCare', name: '醫學保健' },
                { id: 'languageLearning', name: '語言學習' },
                { id: 'literature', name: '文學' },
                { id: 'psychologicalInspirational', name: '心理勵志' },
                { id: 'socialScience', name: '社會科學' },
                { id: 'parenting', name: '親子教養' },
                { id: 'businessAndFinance', name: '商業財經' },
                { id: 'examBooks', name: '考試用書' },
                { id: 'lifeStyle', name: '生活風格' },
                { id: 'travel', name: '旅遊' },
                { id: 'diet', name: '飲食' },
                { id: 'popularScience', name: '科普' },
              ]}
            />
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default BookEdit
