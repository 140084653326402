import MerchandiseList from './list'
import MerchandiseCreate from './create'
import MerchandiseEdit from './edit'

const Merchandise = {
  list: MerchandiseList,
  create: MerchandiseCreate,
  edit: MerchandiseEdit,
}
export default Merchandise
