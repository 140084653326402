import { Create, FileField, FileInput, NumberInput, SimpleForm, TextInput } from 'react-admin'

const MRT_Create = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source='mrtName' label='捷運站名' />
        <FileInput source='file' label='捷運站模型'>
          <FileField source='src' title='title' />
        </FileInput>
        <NumberInput source='size' label='模型大小' />
        <NumberInput source='positionX' label='X座標' />
        <NumberInput source='positionZ' label='Z座標' />
        <NumberInput source='direction' label='旋轉角度' />
      </SimpleForm>
    </Create>
  )
}

export default MRT_Create
