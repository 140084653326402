import { Datagrid, List, TextField, DateField, BooleanField } from 'react-admin'

const MarqueeList = () => {
  return (
    <List title='公告'>
      <Datagrid rowClick='edit'>
        <TextField source='content' label='公告內容' />
        <TextField source='category' label='公告類型' />
        <TextField source='location' label='公告位置' />
        <DateField source='expiredDate' label='有效期限' />
        <BooleanField source='isActive' label='啟用狀態' />
      </Datagrid>
    </List>
  )
}

export default MarqueeList
