import LandscapingCreate from './create'
import LandscapingEdit from './edit'
import LandscapingList from './list'

const landscaping = {
  list: LandscapingList,
  create: LandscapingCreate,
  edit: LandscapingEdit,
}
export default landscaping
