import { Chip, Stack } from '@mui/material'
import { ArrayField, Datagrid, List, NumberField, TextField, useRecordContext } from 'react-admin'

const PositionAndSizeSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.position.map((item, index) => {
        const x = item.positionX
        const y = item.positionY
        const z = item.positionZ
        const size = item.size
        return (
          <Chip
            size='small'
            variant='outlined'
            key={index}
            label={`座標： ${x}, ${y}, ${z} ︱ 尺寸：${size}`}
            color='primary'
          />
        )
      })}
    </Stack>
  )
}

const LandscapingList = () => {
  const username = JSON.parse(localStorage.getItem('user'))?.username

  return (
    <List title='景觀'>
      <Datagrid rowClick='edit'>
        <TextField source='landscapingName' label='造景名稱' />
        <PositionAndSizeSegments label='造景設定(x,y,z,大小)' />
      </Datagrid>
    </List>
  )
}

export default LandscapingList
