import * as React from 'react'
import {
  email,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  ArrayInput,
  FileField,
  NumberInput,
  SimpleFormIterator,
} from 'react-admin'

const LandscapingCreate = () => {
  return (
    <Create title='創建造景'>
      <SimpleForm>
        <TextInput source='landscapingName' label='造景名稱' required />
        <FileInput source='file' label='造景檔案' required>
          <FileField source='src' title='title' />
        </FileInput>
        <ArrayInput source='position' label='造景位置' required>
          <SimpleFormIterator inline>
            <NumberInput source='positionX' label='X座標' helperText={false} required />
            <NumberInput source='positionY' label='Y座標' helperText={false} required />
            <NumberInput source='positionZ' label='Z座標' helperText={false} required />
            <NumberInput source='direction' label='旋轉角度' helperText={false} required />
            <NumberInput source='size' label='造景大小' required />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default LandscapingCreate
