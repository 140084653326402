import * as React from 'react'
import {
  email,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  Edit,
} from 'react-admin'
import { UserContext } from '../../context/user'

const validateEmail = email()

const PortalUserEdit = (props) => {
  const { user } = React.useContext(UserContext)

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='username' label='帳號' disabled={user?.username !== 'fatpoJT957'} />
        <TextInput
          source='email'
          label='電子信箱'
          validate={validateEmail}
          disabled={user?.username !== 'fatpoJT957'}
        />
        <TextInput
          source='tel'
          label='聯絡電話'
          required
          disabled={user?.username !== 'fatpoJT957'}
        />
        <TextInput
          source='owner'
          label='聯絡人姓名'
          required
          disabled={user?.username !== 'fatpoJT957'}
        />
        <ReferenceInput source='managedStoreId' reference='store' perPage={1000000}>
          <SelectInput
            format={(value) => {
              console.log(value)
              return value
            }}
            optionText={(store) => {
              return `${store.storeCode}: ${store.storeName}`
            }}
            label='管理店家'
            disabled={user?.username !== 'fatpoJT957'}
          />
        </ReferenceInput>
        <PasswordInput source='password' label='密碼' />
      </SimpleForm>
    </Edit>
  )
}

export default PortalUserEdit
