import React, { useContext, useState } from 'react'
import { Title } from 'react-admin'
import { SocketContext } from '../context/socket'
import {
  Container,
  ListItemButton,
  ListItemText,
  Collapse,
  Card,
  Grid,
  Box,
  CardContent,
  Input,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ListItemIcon from '@mui/material/ListItemIcon'
import StoreIcon from '@mui/icons-material/Store'
import SendIcon from '@mui/icons-material/Send'

const TextChat = ({ connectedClientList, messageList }) => {
  const socket = useContext(SocketContext)

  return (
    <>
      <Title title='Text Chat' />
      <Container maxWidth='lg' sx={{ marginTop: 2 }}>
        <Card sx={{ background: '#2196f3', color: 'white' }}>
          <CardContent>
            <Typography variant='h5' sx={{ marginBottom: 2 }}>
              聊天列表
            </Typography>
            <Container>
              <Grid container spacing={3} sx={{ marginTop: 2 }}>
                {connectedClientList.map((client, clientIndex) => {
                  return (
                    <ClientChatBox
                      key={clientIndex}
                      client={client}
                      messageList={messageList}
                      socket={socket}
                    />
                  )
                })}
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

const ClientChatBox = ({ client, messageList, socket }) => {
  const [open, setOpen] = React.useState(true)
  const [inputMsg, setInputMsg] = useState('')

  const handleClick = () => {
    setOpen(!open)
  }

  const sendMsg = () => {
    const storeId = JSON.parse(localStorage.getItem('user')).managedStore.id
    if (inputMsg) {
      socket.emit('staffSendMsg', {
        msg: inputMsg,
        sendTo: client.userId,
        storeId: storeId,
      })
      setInputMsg('')
    }
  }

  return (
    <Grid item xs={4}>
      <ListItemButton
        onClick={handleClick}
        sx={{
          color: 'black',
          background: 'white',
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={client.username} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box
          sx={{
            background: 'white',
            color: 'black',
            padding: 2,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
          }}
        >
          {messageList
            .filter((item) => item.from.userId === client.userId || item.sendTo === client.userId)
            .map((item, index) => {
              return item.sendTo === client.userId ? (
                <div key={index}>
                  {item.from.username}: {item.msg}
                </div>
              ) : (
                <div key={index}>
                  {client.username}: {item.msg}
                </div>
              )
            })}
          <Input
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMsg()
              }
            }}
            sx={{ paddingX: 1, mt: 1 }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={sendMsg} edge='end'>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
            label='Password'
          />
        </Box>
      </Collapse>
    </Grid>
  )
}

export default TextChat
