import { threeDFormat, videoFormat, imageFormat } from '../const/filesType'
export const validateMaxAmount = (data) => {
  return data.length > 6 ? '最多上傳三個檔案' : undefined
}

export const validateFileSize = (data) => {
  const overSizeIndex = data.findIndex((item) => {
    if (item.rawFile) {
      return item.rawFile.size > 8000000
    }
    return false
  })
  return overSizeIndex !== -1 ? `第 ${overSizeIndex + 1} 個檔案超出大小` : undefined
}

export const validateFileSameName = (data) => {
  const findSameNameFile = (element, elementIndex) => {
    if (element.rawFile) {
      return (
        data.findIndex((item) => {
          if (item.rawFile) {
            return item.title === element.title
          } else {
            return item.name === element.title
          }
        }) !== elementIndex
      )
    } else {
      return (
        data.findIndex((item) => {
          if (item.rawFile) {
            return item.title === element.name
          } else {
            return item.name === element.name
          }
        }) !== elementIndex
      )
    }
  }
  const sameName = data.some(findSameNameFile)
  return sameName ? 'You can not upload the same name files' : undefined
}

export const validate3DFile = (data) => {
  const have3DFile = data.some((item) => {
    if (item.rawFile) {
      return threeDFormat.includes(item.rawFile.name.split('.').pop())
    }
    return threeDFormat.includes(item.name.split('.').pop())
  })
  if (have3DFile) {
    return data.length > 1 ? '使用 3d 檔案時只能上傳一個檔案' : undefined
  }
  return undefined
}

export const validateFileType = (data) => {
  const acceptList = imageFormat.concat(videoFormat).concat(threeDFormat)
  const haveInvalidFile = data.some((item) => {
    if (item.rawFile) {
      return !acceptList.includes(item.rawFile.name.split('.').pop())
    }
    return false
  })
  return haveInvalidFile ? `上傳檔案格式不支援，目前僅接受${acceptList.join()}` : undefined
}
