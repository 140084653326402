import { useState } from 'react'
import { Admin, Resource, CustomRoutes } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider'
import MyLayout from './layout/MyLayout'
// import user from './resource/users'
import portalUser from './resource/portalUsers'
import store from './resource/stores'
import merchandise from './resource/merchandise'
import landscaping from './resource/landscaping'
import marquee from './resource/marquee'
import MRT from './resource/MRT'
import Book from './resource/book'
import TextChat from './customRoute/TextChat'
import { Route } from 'react-router-dom'
import { MessageListContext, ConnectedClientListContext } from './context/socket'
import VideoChat from './customRoute/VideoChat'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { UserContext } from './context/user'

const App = () => {
  const [connectedClientList, setConnectedClientList] = useState([])
  const [messageList, setMessageList] = useState([])
  const [user, setUser] = useState(null)
  const username = user?.username

  return (
    <DndProvider backend={HTML5Backend}>
      <UserContext.Provider value={{ setUser, user }}>
        <ConnectedClientListContext.Provider value={{ setConnectedClientList }}>
          <MessageListContext.Provider value={{ setMessageList }}>
            <Admin
              dataProvider={dataProvider}
              authProvider={authProvider}
              requireAuth
              layout={MyLayout}
            >
              {/* <Resource name='user' list={user.list} /> */}
              <Resource
                name='portalUser'
                list={portalUser.list}
                create={username === 'fatpoJT957' && portalUser.create}
                edit={portalUser.edit}
              />
              <Resource
                name='store'
                list={store.list}
                create={username === 'fatpoJT957' && store.create}
                edit={store.edit}
              />
              <Resource
                name='merchandise'
                list={merchandise.list}
                create={merchandise.create}
                edit={merchandise.edit}
              />
              {username === 'fatpoJT957' && (
                <Resource
                  name='landscaping'
                  list={landscaping.list}
                  create={landscaping.create}
                  edit={landscaping.edit}
                />
              )}
              {username === 'fatpoJT957' && (
                <Resource
                  name='marquee'
                  list={marquee.list}
                  create={marquee.create}
                  edit={marquee.edit}
                />
              )}
              {username === 'fatpoJT957' && (
                <Resource name='mrt' list={MRT.list} create={MRT.create} edit={MRT.edit} />
              )}
              <Resource name='book' list={Book.list} create={Book.create} edit={Book.edit} />

              <CustomRoutes>
                <Route
                  path='/textChat'
                  element={
                    <TextChat messageList={messageList} connectedClientList={connectedClientList} />
                  }
                />
                <Route
                  path='/videoChat'
                  element={
                    <VideoChat
                      messageList={messageList}
                      connectedClientList={connectedClientList}
                    />
                  }
                />
              </CustomRoutes>
            </Admin>
          </MessageListContext.Provider>
        </ConnectedClientListContext.Provider>
      </UserContext.Provider>
    </DndProvider>
  )
}

export default App
