import {
  Edit,
  FileField,
  FileInput,
  NumberInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin'

const MRT_File = () => {
  const record = useRecordContext()
  return (
    <FileInput
      source='file'
      label='捷運站模型'
      format={(value) => {
        if (typeof value === 'string') {
          return {
            src: `${process.env.REACT_APP_CLOUDFRONT}/mrt/${record.id}/${value}`,
            title: value,
          }
        } else {
          return value
        }
      }}
    >
      <FileField source='src' title='title' />
    </FileInput>
  )
}

const MRT_Edit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source='mrtName' label='捷運站名' />
        <MRT_File />
        <NumberInput source='size' label='模型大小' />
        <NumberInput source='positionX' label='X座標' />
        <NumberInput source='positionZ' label='Z座標' />
        <NumberInput source='direction' label='旋轉角度' />
      </SimpleForm>
    </Edit>
  )
}

export default MRT_Edit
