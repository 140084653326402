import * as React from 'react'
import {
  email,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

const validateEmail = email()

const PortalUserCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='username' label='帳號' />
        <TextInput source='email' label='電子信箱' validate={validateEmail} />
        <TextInput source='tel' label='聯絡電話' required />
        <TextInput source='owner' label='聯絡人姓名' required />
        <ReferenceInput source='managedStoreId' reference='store' perPage={1000000}>
          <SelectInput
            optionText={(store) => {
              return `${store.storeCode}: ${store.storeName}`
            }}
            label='管理店家'
          />
        </ReferenceInput>
        <PasswordInput source='password' label='密碼' />
      </SimpleForm>
    </Create>
  )
}

export default PortalUserCreate
