import { useRef, useContext, useEffect, useState } from 'react'
import { Title } from 'react-admin'
import { PeerContext } from '../context/socket'
import { Container, ListItemText, Card, Grid, Box, CardContent, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import StoreIcon from '@mui/icons-material/Store'

const VideoChat = ({ connectedClientList }) => {
  const [myStream, setMyStream] = useState(null)

  useEffect(() => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // 首先，如果有getUserMedia的话，就获得它
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

        // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
        }

        // 否则，为老的navigator.getUserMedia方法包裹一个Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((myStream) => {
      setMyStream(myStream)
    })
  }, [])

  return (
    <>
      <Title title='Video Chat' />
      <Container maxWidth='lg' sx={{ marginTop: 2 }}>
        <Card sx={{ background: '#2196f3', color: 'white' }}>
          <CardContent>
            <Typography variant='h5' sx={{ marginBottom: 2 }}>
              視訊列表
            </Typography>
            <Container>
              <Grid container spacing={3} sx={{ marginTop: 2 }}>
                {connectedClientList.map((client, clientIndex) => {
                  return <ClientChatBox key={clientIndex} myStream={myStream} client={client} />
                })}
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

const ClientChatBox = ({ client, myStream }) => {
  const myVideo = useRef(null)

  const opponentVideo = useRef(null)

  const peer = useContext(PeerContext)

  useEffect(() => {
    if (peer && myStream) {
      peer.on('call', (call) => {
        call.answer(myStream)
        console.log('got call from client')
        call.on('stream', (opponentStreamData) => {
          opponentVideo.current.srcObject = opponentStreamData
          opponentVideo.current.play()
        })
      })
    }
  }, [peer, myStream])

  useEffect(() => {
    myVideo.current.srcObject = myStream
    myVideo.current.play()
  }, [myStream])

  return (
    <Grid item xs={4}>
      <Box
        sx={{
          background: 'white',
          color: 'black',
          padding: 2,
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
        }}
      >
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={client.username} />
        <div>
          <video id='opponentVideo' ref={opponentVideo} width='100%' height='100%'></video>
          <video id='myvideo' ref={myVideo} width='30%' height='30%'></video>
        </div>
      </Box>
    </Grid>
  )
}

export default VideoChat
