import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_HOST

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = JSON.parse(localStorage.getItem('token')) ?? null
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const uploadImage = (file, uploadDestination, contentType) => {
  const reader = new FileReader()
  reader.onload = () => {
    axios({
      method: 'put',
      url: uploadDestination,
      data: reader.result,
      headers: {
        'Content-Type': contentType,
      },
    })
  }
  reader.readAsArrayBuffer(file.rawFile)
}

const dataProvider = {
  getList: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify({ [field]: order === 'ASC' ? 1 : -1 }),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    if (resource === 'merchandise') {
      return httpClient(
        `${apiUrl}/store/${managedStoreId || 'admin'}/${resource}?${stringify(query)}`
      ).then(({ headers, json }) => {
        console.timeLog(json)
        return {
          data: json.data.map((resource) => ({ ...resource, id: resource._id })),
          // consider use 'count' in response body instead of http header in the future
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
        }
      })
    }
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data.map((resource) => ({ ...resource, id: resource._id })),
        // consider use 'count' in response body instead of http header in the future
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      }
    })
  },
  getOne: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    if (resource === 'merchandise') {
      return httpClient(
        `${apiUrl}/store/${managedStoreId || 'admin'}/merchandise/${params.id}`
      ).then(({ headers, json }) => {
        return {
          data: { ...json.data, id: json.data._id },
        }
      })
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      return {
        data: { ...json.data, id: json.data._id },
      }
    })
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`
    return httpClient(url).then(({ json }) => ({
      data: json.data.map((resource) => ({ ...resource, id: resource._id })),
    }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${JSON.stringify(query)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json.map((resource) => ({ ...resource, id: resource._id })),
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }))
  },

  update: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    if (resource === 'store') {
      // 選到檔案位置
      const file1 = params.data.wallImage1
      params.data.wallImage1 = params.data.wallImage1?.title
      if (!file1.rawFile) {
        delete params.data.wallImage1
      }

      const file2 = params.data.wallImage2
      if (file2) {
        params.data.wallImage2 = params.data.wallImage2.title
      }
      if (!file2?.rawFile) {
        delete params.data.wallImage2
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (file1.rawFile) {
          uploadImage(file1, json.data.uploadUrl1, 'image/jpeg')
        }

        if (file2?.rawFile) {
          uploadImage(file2, json.data.uploadUrl2, 'image/jpeg')
        }

        return {
          data: { ...json.data.newStore, id: json.data._id },
        }
      })
    }
    if (resource === 'landscaping') {
      console.log(params.data.file)

      const file = params.data.file

      params.data.file = params.data.file.title

      if (!file.rawFile) {
        delete params.data.file
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (file.rawFile) {
          uploadImage(file, json.data.uploadUrl, '*')
        }
        return { data: { ...json.data.newLanscaping, id: json.data._id } }
      })
    }
    if (resource === 'merchandise') {
      const coverFile = params.data.cover

      if (!coverFile.rawFile) {
        delete params.data.cover
      } else {
        params.data.cover = params.data.cover.title
      }

      // handle media file
      const mediaFiles = params.data.media
      params.data.media = params.data.media.map((item) => {
        if (item.rawFile) {
          return {
            type: item.rawFile?.path.split('.')[1] || item.type,
            name: item.title,
            hasFile: !!item.rawFile,
          }
        } else {
          return item
        }
      })

      return httpClient(
        `${apiUrl}/store/${managedStoreId || params.data.storeId}/${resource}/${params.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => {
        // upload cover image
        if (coverFile.rawFile) {
          uploadImage(coverFile, json.data.uploadCoverUrl, '*')
        }

        // upload media files
        mediaFiles
          .filter((item) => item.rawFile)
          .forEach((item, index) => {
            uploadImage(item, json.data.uploadMediaUrls[index], '*')
          })

        return {
          data: { ...json.data.newStore, id: json.data._id },
        }
      })
    }
    if (resource === 'mrt') {
      const file = params.data.file

      params.data.file = params.data.file.title

      if (!file.rawFile) {
        delete params.data.file
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (file.rawFile) {
          uploadImage(file, json.data.uploadUrl, '*')
        }
        return { data: { ...json.data.newMRT, id: json.data._id } }
      })
    }
    if (resource == 'book') {
      const coverFile = params.data.cover

      params.data.cover = {
        name: coverFile.rawFile ? params.data.cover.title : params.data.cover,
        hasFile: !!coverFile.rawFile,
      }

      // handle media file
      const mediaFiles = params.data.media
      params.data.media = params.data.media.map((item) => {
        if (item.rawFile) {
          return {
            type: item.rawFile?.path.split('.')[1] || item.type,
            name: item.title,
            hasFile: !!item.rawFile,
          }
        } else {
          return item
        }
      })

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (coverFile.rawFile) {
          uploadImage(coverFile, json.data.uploadCoverUrl, '*')
        }
        mediaFiles
          .filter((item) => item.rawFile)
          .forEach((item, index) => {
            uploadImage(item, json.data.uploadMediaUrls[index], '*')
          })
        return { data: { ...json.data.newBook, id: json.data._id } }
      })
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return {
        data: { ...json.data, id: json.data._id },
      }
    })
  },

  updateMany: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    if (resource === 'merchandise') {
      return httpClient(`${apiUrl}/store/${managedStoreId || 'admin'}/${resource}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        return {
          data: json.data.map((resource) => ({ ...resource, id: resource._id })),
        }
      })
    }
  },

  create: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    if (resource === 'store') {
      // 選到檔案位置
      const file1 = params.data.wallImage1
      params.data.wallImage1 = params.data.wallImage1.title

      const file2 = params.data.wallImage2
      if (file2) {
        params.data.wallImage2 = params.data.wallImage2.title
      }

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        uploadImage(file1, json.data.uploadUrl1, 'image/jpeg')
        if (file2) {
          uploadImage(file2, json.data.uploadUrl2, 'image/jpeg')
        }
        return {
          data: { ...json.data.newStore, id: json.data._id },
        }
      })
    }
    if (resource === 'landscaping') {
      console.log(params.data.file)

      const file = params.data.file
      params.data.file = params.data.file.title

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        uploadImage(file, json.data.uploadUrl, '*')
        return { data: { ...json.data.newLanscaping, id: json.data._id } }
      })
    }
    if (resource === 'merchandise') {
      const coverFile = params.data.cover

      const mediaFiles = params.data.media

      // handle upload file
      params.data.cover = params.data.cover.title
      params.data.media = params.data.media.map((item) => ({
        type: item.rawFile.path.split('.')[1],
        name: item.title,
      }))

      return httpClient(`${apiUrl}/store/${managedStoreId || params.data.storeId}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        // upload cover image
        const reader = new FileReader()
        reader.onload = () => {
          axios({
            method: 'put',
            url: json.data.uploadCoverUrl,
            data: reader.result,
            headers: {
              'Content-Type': 'image/jpeg',
            },
          })
        }
        reader.readAsArrayBuffer(coverFile.rawFile)

        // upload media files
        mediaFiles.forEach((item, index) => {
          const reader = new FileReader()
          reader.onload = () => {
            axios({
              method: 'put',
              url: json.data.uploadMediaUrls[index],
              data: reader.result,
            })
          }
          reader.readAsArrayBuffer(item.rawFile)
        })

        return {
          data: { ...json.data.newStore, id: json.data._id },
        }
      })
    }
    if (resource === 'mrt') {
      const file = params.data.file
      params.data.file = params.data.file.title

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        uploadImage(file, json.data.uploadUrl, '*')
        return { data: { ...json.data.newMRT, id: json.data._id } }
      })
    }
    if (resource === 'book') {
      const coverFile = params.data.cover
      params.data.cover = params.data.cover.title

      const mediaFiles = params.data.media
      params.data.media = params.data.media.map((item) => ({
        type: item.rawFile.path.split('.')[1],
        name: item.title,
      }))

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        uploadImage(coverFile, json.data.uploadCoverUrl, '*')

        mediaFiles.forEach((item, index) => {
          uploadImage(item, json.data.uploadMediaUrls[index], '*')
        })

        return {
          data: { ...json.data.newStore, id: json.data._id },
        }
      })
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return {
        data: { ...json.data, id: json.data._id },
      }
    })
  },
  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ ...json, id: json._id }))
  },
  deleteMany: (resource, params) => {
    const managedStoreId = JSON.parse(localStorage.getItem('user'))?.managedStore.id

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    if (resource === 'merchandise') {
      return httpClient(
        `${apiUrl}/store/${managedStoreId || 'admin'}/${resource}?${stringify(query)}`,
        {
          method: 'DELETE',
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => {
        return { data: json.data }
      })
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return { data: json.data }
    })
  },
}

export default dataProvider
