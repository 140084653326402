import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  TextInput,
  ImageField,
  required,
  AutocompleteArrayInput,
  SelectInput,
  FileField,
} from 'react-admin'
import { UserContext } from '../../context/user'
import { useWatch } from 'react-hook-form'

const MediaInputs = () => {
  const storeLevel = useWatch({ name: 'storeLevel' })
  const wallImage1 = useWatch({ name: 'wallImage1' })
  console.log(wallImage1)
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <div style={{ width: '48%' }}>
        <ImageInput
          source='wallImage1'
          label='一號看板'
          accept={storeLevel === 6 ? 'video/*' : 'image/*'}
          validate={required()}
        >
          {/* 判斷如果是 video 就用 FileField，如果是 image 就用 imageFiled */}
          {storeLevel === 6 ? (
            <FileField source='src' title='title' />
          ) : (
            <ImageField source='src' title='title' />
          )}
        </ImageInput>
      </div>
      {storeLevel !== 6 && (
        <div style={{ width: '48%' }}>
          <ImageInput source='wallImage2' label='二號看板' accept='image/*' validate={required()}>
            <ImageField source='src' title='store image' />
          </ImageInput>
        </div>
      )}
    </div>
  )
}

const PositionAndStoreInfoTab = () => {
  const storeLevel = useWatch({ name: 'storeLevel' })
  const { user } = React.useContext(UserContext)
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <div style={{ width: '48%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextInput
            style={{ width: '48%' }}
            label='商店代號'
            source='storeCode'
            validate={required()}
            disabled={user?.username !== 'fatpoJT957'}
          />
          <TextInput
            style={{ width: '48%' }}
            label='商店名稱'
            source='storeName'
            disabled={user?.username !== 'fatpoJT957'}
            validate={required()}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SelectInput
            style={{ width: '48%' }}
            source='storeLevel'
            label='店面類型'
            required
            choices={[
              { id: 1, name: '初版店面' },
              { id: 2, name: '市集店面' },
              { id: 3, name: '一般店面' },
              { id: 4, name: '精品店面' },
              { id: 5, name: '客製化店面' },
              { id: 6, name: '電視牆' },
            ]}
            disabled={user?.username !== 'fatpoJT957'}
          />
          <SelectInput
            style={{ width: '48%' }}
            source='direction'
            label='店面朝向'
            required
            choices={[
              { id: 0, name: '東' },
              { id: 1, name: '南' },
              { id: 2, name: '西' },
              { id: 3, name: '北' },
            ]}
            disabled={user?.username !== 'fatpoJT957'}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextInput
            style={{ width: storeLevel === 6 ? '30%' : '48%' }}
            label='X 座標'
            source='positionX'
            validate={required()}
            disabled={user?.username !== 'fatpoJT957'}
          />
          {storeLevel === 6 && (
            <TextInput
              style={{ width: '30%' }}
              label='Y 座標'
              source='positionY'
              disabled={user?.username !== 'fatpoJT957'}
              defaultValue={0}
            />
          )}
          <TextInput
            style={{ width: storeLevel === 6 ? '30%' : '48%' }}
            label='Z 座標'
            source='positionZ'
            validate={required()}
            disabled={user?.username !== 'fatpoJT957'}
          />
        </div>
        {storeLevel === 6 && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextInput
              style={{ width: '30%' }}
              label='X 方向大小'
              source='xScale'
              disabled={user?.username !== 'fatpoJT957'}
            />
            {storeLevel === 6 && (
              <TextInput
                style={{ width: '30%' }}
                label='Y 方向大小'
                source='yScale'
                disabled={user?.username !== 'fatpoJT957'}
              />
            )}
            <TextInput
              style={{ width: '30%' }}
              label='Z 方向大小'
              source='zScale'
              disabled={user?.username !== 'fatpoJT957'}
            />
          </div>
        )}
        <div>
          <TextInput
            style={{ width: '48%' }}
            label='商店大小'
            source='storeSize'
            validate={required()}
            disabled={user?.username !== 'fatpoJT957'}
          />
        </div>
        <div>
          <TextInput multiline style={{ width: '100%' }} label='商店簡介' source='description' />
        </div>
      </div>
      <div style={{ width: '48%' }}>
        <img src='/mini_map.png' width='100%' height='100%' alt='mina map' />
      </div>
    </div>
  )
}

const StoreCreate = () => {
  const { user } = React.useContext(UserContext)
  return (
    <Create>
      <TabbedForm>
        <FormTab label='基本資訊'>
          <SelectInput
            style={{ width: '48%' }}
            source='storeLevel'
            label='店面類型'
            required
            choices={[
              { id: 1, name: '初版店面' },
              { id: 2, name: '市集店面' },
              { id: 3, name: '一般店面' },
              { id: 4, name: '精品店面' },
              { id: 5, name: '客製化店面' },
              { id: 6, name: '電視牆' },
            ]}
            disabled={user?.username !== 'fatpoJT957'}
          />
          <MediaInputs />
          <TextInput label='臉書網址' source='fbLink' fullWidth />
          <TextInput label='Line 連結' source='lineLink' fullWidth />
          <TextInput label='商店網址' source='socialLink' fullWidth />
        </FormTab>
        <FormTab label='類別標籤'>
          <AutocompleteArrayInput
            source='purpose'
            choices={[
              { id: 'food', name: '食' },
              { id: 'clothes', name: '衣' },
              { id: 'stay', name: '住' },
              { id: 'goOut', name: '行' },
              { id: 'educate', name: '育' },
              { id: 'fun', name: '樂' },
              { id: 'groceries', name: '雜貨' },
            ]}
          />
          <AutocompleteArrayInput
            source='target'
            choices={[
              { id: 'baby', name: '嬰兒' },
              { id: 'child', name: '孩童' },
              { id: 'teenager', name: '青少年' },
              { id: 'adult', name: '成年' },
              { id: 'old', name: '老人' },
            ]}
          />
          <AutocompleteArrayInput
            source='price'
            choices={[
              { id: 'cheap', name: '$(0~1000)' },
              { id: 'normal', name: '$$(1000~5000)' },
              { id: 'expensive', name: '$$$(5000~100000)' },
              { id: 'veryExpensive', name: '$$$$(100000~)' },
            ]}
          />
          {/* <TextInput label='篩選 1 名稱' source='filter1Title' />
          <TextInput label='篩選 2 名稱' source='filter2Title' />
          <TextInput label='篩選 3 名稱' source='filter3Title' /> */}
        </FormTab>
        <FormTab label='位置與商店資訊'>
          <PositionAndStoreInfoTab />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default StoreCreate
