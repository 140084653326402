import MRT_Create from './create'
import MRT_Edit from './edit'
import MRT_List from './list'

const MRT = {
  list: MRT_List,
  edit: MRT_Edit,
  create: MRT_Create,
}
export default MRT
