import {
  AutocompleteArrayInput,
  Create,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  TabbedForm,
  TextInput,
} from 'react-admin'

const BookCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label='書本'>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <TextInput label='書名' source='bookName' style={{ width: '31%' }} />
              <TextInput label='作者' source='author' style={{ width: '31%' }} />
              <TextInput label='出版商' source='publisher' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='X座標' source='positionX' style={{ width: '31%' }} />
              <NumberInput label='Y座標' source='positionY' style={{ width: '31%' }} />
              <NumberInput label='Z座標' source='positionZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='書本大小X' source='bookSizeX' style={{ width: '31%' }} />
              <NumberInput label='書本大小Y' source='bookSizeY' style={{ width: '31%' }} />
              <NumberInput label='書本大小Z' source='bookSizeZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <NumberInput label='旋轉X' source='rotationX' style={{ width: '31%' }} />
              <NumberInput label='旋轉Y' source='rotationY' style={{ width: '31%' }} />
              <NumberInput label='旋轉Z' source='rotationZ' style={{ width: '31%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <TextInput label='顏色' source='bookColor' style={{ width: '48%' }} />
              <TextInput label='贊助商' source='supplier' style={{ width: '48%' }} />
            </div>
            <ImageInput label='封面' source='cover'>
              <ImageField source='src' title='book image' />
            </ImageInput>

            <FileInput source='media' multiple>
              <FileField source='src' title='title' src='src' />
            </FileInput>

            <TextInput source='mediaLink' multiline />
            <TextInput source='storeLink' multiline />

            <AutocompleteArrayInput
              source='category'
              choices={[
                { id: 'comics', name: '漫畫圖文' },
                { id: 'lightNovel', name: '輕小說' },
                { id: 'humanitiesAndHistoryAndPhilosophy', name: '人文史哲' },
                { id: 'artDesign', name: '藝術設計' },
                { id: 'medicalCare', name: '醫學保健' },
                { id: 'languageLearning', name: '語言學習' },
                { id: 'literature', name: '文學' },
                { id: 'psychologicalInspirational', name: '心理勵志' },
                { id: 'socialScience', name: '社會科學' },
                { id: 'parenting', name: '親子教養' },
                { id: 'businessAndFinance', name: '商業財經' },
                { id: 'examBooks', name: '考試用書' },
                { id: 'lifeStyle', name: '生活風格' },
                { id: 'travel', name: '旅遊' },
                { id: 'diet', name: '飲食' },
                { id: 'popularScience', name: '科普' },
              ]}
            />
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default BookCreate
