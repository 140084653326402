import { Datagrid, List, TextField } from 'react-admin'

const MRT_List = () => {
  return (
    <List>
      <Datagrid rowClick='edit'>
        <TextField source='mrtName' label='捷運站名' />
      </Datagrid>
    </List>
  )
}

export default MRT_List
