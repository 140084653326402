import { RecordContextProvider } from 'react-admin'
import { TableCell, TableRow, Checkbox } from '@mui/material'
import * as React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'

const MyDatagridRow = ({
  sortMerchandise,
  localData,
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => {
  const ref = React.useRef(null)

  const dataIndex = localData?.findIndex((item) => {
    return record?.id === item?.id
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PAGE,
    item: { id, dataIndex },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.PAGE,
    collect: (monitor) => {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      //獲得從drag return過來的dataindex，也就是被抓起物件的index
      const dragIndex = item.dataIndex
      //獲得在當下這個component中的dataindex，也就是滑鼠懸浮在哪的index
      const hoverIndex = dataIndex

      if (dragIndex === hoverIndex) {
        return
      }
      //這個會獲得，當下滑鼠懸浮在哪個物件上的位置
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      //判斷物件的中心位置
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // 判斷滑鼠位置
      const clientOffset = monitor.getClientOffset()

      // 滑鼠的y減掉 當下滑鼠在的物件上邊界的y
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // 只有在鼠標穿過物件高度的一半時才執行移動
      // 向下拖動時，僅在鼠標低於 50% 時移動
      // 向上拖動時，僅當鼠標在 50% 以上時移動

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      sortMerchandise(dragIndex, hoverIndex)
      item.dataIndex = hoverIndex
    },
  })
  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <>
      <RecordContextProvider value={record}>
        <TableRow ref={ref} style={{ opacity }} data-handler-id={handlerId}>
          {/* first column: selection checkbox */}
          <TableCell style={{ padding: '0 12px 0 16px' }}>
            {selectable && (
              <Checkbox
                style={{ padding: 0 }}
                checked={selected}
                onClick={(event) => onToggleItem(id, event)}
              />
            )}
          </TableCell>
          {/* data columns based on children */}
          {React.Children.map(children, (field) => (
            <TableCell style={{ padding: '0 12px 0 16px' }} key={`${id}-${field.props.source}`}>
              {field}
            </TableCell>
          ))}
        </TableRow>
      </RecordContextProvider>
    </>
  )
}

export default MyDatagridRow
