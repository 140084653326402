import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  TextInput,
  ImageField,
  required,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  FileInput,
  FileField,
} from 'react-admin'
import {
  validateMaxAmount,
  validateFileSize,
  validate3DFile,
  validateFileType,
  validateFileSameName,
} from '../../utils/validation'

const MerchandiseCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label='Required'>
          <TextInput label='商品名稱' source='merchandiseName' validate={required()} />
          <TextInput multiline label='商品說明' source='description' />
          <TextInput source='linkLabel' label='商品連結文案' />
          <TextInput label='商品連結' source='link' />
          {/* <TextInput label='Price' source='price' validate={required()} /> */}
          <ImageInput source='cover' label='商品大圖' accept='image/*' validate={required()}>
            <ImageField source='src' title='title' />
          </ImageInput>
          <FileInput
            source='media'
            label='商品輪播圖(單個檔案限制 5 MB)'
            validate={[
              required(),
              validateFileType,
              validateMaxAmount,
              validateFileSize,
              validate3DFile,
              validateFileSameName,
            ]}
            multiple
            format={(data) => {
              return data
            }}
          >
            <FileField source='src' title='title' />
          </FileInput>

          {/* <ArrayInput source='variant'>
            <SimpleFormIterator inline>
              <TextInput source='style' label='樣式' helperText={false} />
              <TextInput source='size' label='尺寸' helperText={false} />
              <NumberInput source='remainAmount' label='數量' helperText={false} />
            </SimpleFormIterator>
          </ArrayInput> */}
        </FormTab>
        <FormTab label='Recommand'>
          {/* <AutocompleteArrayInput
          source='purpose'
          choices={[
            { id: 'food', name: '食' },
            { id: 'clothes', name: '衣' },
            { id: 'stay', name: '住' },
            { id: 'goOut', name: '行' },
            { id: 'educate', name: '育' },
            { id: 'fun', name: '樂' },
            { id: 'groceries', name: '雜貨' },
          ]}
        />
        <AutocompleteArrayInput
          source='target'
          choices={[
            { id: 'baby', name: '嬰兒' },
            { id: 'child', name: '孩童' },
            { id: 'teenager', name: '青少年' },
            { id: 'adult', name: '成年' },
            { id: 'old', name: '老人' },
          ]}
        />
        <AutocompleteArrayInput
          source='price'
          choices={[
            { id: 'cheap', name: '$(0~1000)' },
            { id: 'normal', name: '$$(1000~5000)' },
            { id: 'expensive', name: '$$$(5000~100000)' },
            { id: 'veryExpensive', name: '$$$$(100000~)' },
          ]}
        /> */}
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default MerchandiseCreate
