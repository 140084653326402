import {
  List,
  Datagrid,
  TextField,
  TextInput,
  useRecordContext,
  CreateButton,
  TopToolbar,
  useGetList,
  DatagridBody,
  EditButton,
  Button,
  useUpdateMany,
  useDeleteMany,
  DeleteButton,
  BulkDeleteButton,
  useDelete,
} from 'react-admin'
import { Stack, Chip } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import MyDatagridRow from './MyDatagridRow.js'
import SaveIcon from '@mui/icons-material/Save'

const PriceSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.price.map((priceType) => (
        <Chip size='small' key={priceType} label={priceType} color='primary' />
      ))}
    </Stack>
  )
}

const PurposeSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.purpose.map((purposeType) => (
        <Chip size='small' key={purposeType} label={purposeType} color='success' />
      ))}
    </Stack>
  )
}

const TargetSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.target.map((targetType) => (
        <Chip size='small' key={targetType} label={targetType} color='success' variant='outlined' />
      ))}
    </Stack>
  )
}

const storeFilters = [
  <TextInput label='Search' source='storeName' alwaysOn />,
  <TextInput label='Description' source='description' defaultValue='鞋' />,
  <TextInput label='Price' source='price' />,
]

const MerchandiseListAction = ({ total, localData, adjusted, setLocalData, setDataFromApi }) => {
  const managedStoreLevel = JSON.parse(localStorage.getItem('user'))?.managedStore?.level

  const [updateMany, { data, isLoading, error }] = useUpdateMany('merchandise', {
    ids: localData.map((item) => item.id),
    data: localData,
  })

  useEffect(() => {
    if (data) {
      setDataFromApi(JSON.parse(JSON.stringify(data)))
      setLocalData(JSON.parse(JSON.stringify(data)))
    }
  }, [data])

  return (
    <TopToolbar>
      {adjusted && (
        <Button
          label='SAVE'
          onClick={() => {
            updateMany()
          }}
        >
          <SaveIcon />
        </Button>
      )}
      <CreateButton label='CREATE' disabled={total >= 6 && managedStoreLevel == 1} />
    </TopToolbar>
  )
}

const DeleteBulkButton = ({ localData, setLocalData, setDataFromApi, selectedIds }) => {
  const [deleteMany, { data, isLoading, error }] = useDeleteMany('merchandise', {
    ids: selectedIds,
    data: localData,
  })

  useEffect(() => {
    if (data) {
      const newLocalData = JSON.parse(JSON.stringify(localData))

      data.forEach((item) => {
        const selectedIndex = newLocalData.findIndex((merchandise) => {
          return merchandise.id == item.id
        })
        newLocalData.splice(selectedIndex, 1)
      })
      setDataFromApi(JSON.parse(JSON.stringify(data)))
      setLocalData(newLocalData)
    }
  }, [data])

  return (
    <>
      <BulkDeleteButton
        onClick={() => {
          deleteMany()
        }}
      />
    </>
  )
}

const MerchandiseList = (props) => {
  const [localData, setLocalData] = useState([])
  const [dataFromApi, setDataFromApi] = useState([])
  const [total, setTotal] = useState(0)

  const sortMerchandise = useCallback((dragIndex, hoverIndex) => {
    setLocalData((prev) => {
      const newLocalData = JSON.parse(JSON.stringify(prev))
      const originalSortIndex = [
        newLocalData[dragIndex].sortIndex,
        newLocalData[hoverIndex].sortIndex,
      ]
      newLocalData.splice(dragIndex, 1)
      newLocalData.splice(hoverIndex, 0, prev[dragIndex])
      newLocalData[dragIndex].sortIndex = originalSortIndex[0]
      newLocalData[hoverIndex].sortIndex = originalSortIndex[1]
      return newLocalData
    })
  }, [])

  return (
    <>
      <List
        title='商品'
        queryOptions={{
          onSettled: (data) => {
            if (localData.length === 0) {
              setLocalData(JSON.parse(JSON.stringify(data.data || [])))
              setDataFromApi(JSON.parse(JSON.stringify(data.data || [])))
              setTotal(data.total)
            }
          },
        }}
        actions={
          <MerchandiseListAction
            adjusted={JSON.stringify(dataFromApi) !== JSON.stringify(localData)}
            total={total}
            localData={localData}
            setLocalData={setLocalData}
            setDataFromApi={setDataFromApi}
          />
        }
        sort={{ field: 'sortIndex', order: 'ASC' }}
        perPage={100}
        pagination={null}
      >
        <Datagrid
          data={localData}
          body={
            <DatagridBody
              row={<MyDatagridRow localData={localData} sortMerchandise={sortMerchandise} />}
            />
          }
          bulkActionButtons={
            <DeleteBulkButton
              localData={localData}
              setLocalData={setLocalData}
              setDataFromApi={setDataFromApi}
            />
          }
        >
          <TextField source='merchandiseName' label='商品名稱' />
          {/* <TextField source='price' label='價格' /> */}
          <TextField source='lastUpdatedTime' label='修改日期' />
          <EditButton label='編輯' />
        </Datagrid>
      </List>
    </>
  )
}

export default MerchandiseList
