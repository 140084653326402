import { Layout } from 'react-admin'
import { useEffect, useContext, useState } from 'react'
import MyMenu from './MyMenu'
import {
  SocketContext,
  socket,
  ConnectedClientListContext,
  MessageListContext,
  PeerContext,
} from '../context/socket'
import { UserContext } from '../context/user'
import { useAuthState } from 'react-admin'

const MyLayout = (props) => {
  const { children } = props
  const { setConnectedClientList } = useContext(ConnectedClientListContext)
  const { setMessageList } = useContext(MessageListContext)
  const { setUser } = useContext(UserContext)
  const [peerContext, setPeerContext] = useState(undefined)

  async function getPeer(user) {
    const { default: Peer } = await import('peerjs')

    const peer = new Peer(user.managedStore.id, {
      host: process.env.REACT_APP_API_DOMAIN,
      path: '/peer',
      port: 80,
    })

    setPeerContext(peer)
  }

  // useEffect(() => {
  //   // 登入後取得 localstorage 裡的 staff 資訊
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   socket.auth = {
  //     username: user?.username,
  //     userType: 'staff',
  //     storeId: user.managedStoreId,
  //     userId: user.id,
  //   }

  //   getPeer(user)
  //   return () => {
  //   }
  // }, [setConnectedClientList, setMessageList])
  const identity = useAuthState()
  useEffect(() => {
    if (identity) {
      setUser(JSON.parse(localStorage.getItem('user')))
    }
  }, [identity.authenticated])

  return (
    <SocketContext.Provider value={socket}>
      <PeerContext.Provider value={peerContext}>
        <Layout menu={MyMenu}>{children}</Layout>
      </PeerContext.Provider>
    </SocketContext.Provider>
  )
}

export default MyLayout
