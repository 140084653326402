import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  Edit,
  ImageInput,
  TextInput,
  ImageField,
  required,
  FileInput,
  FileField,
  useRecordContext,
  Toolbar,
  useNotify,
  useRedirect,
  SaveButton,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import {
  validateMaxAmount,
  validateFileSize,
  validate3DFile,
  validateFileType,
  validateFileSameName,
} from '../../utils/validation'

const formatImg = (value, merchandiseId, storeId) => {
  // fatpo 會看不到，以後得想辦法
  if (typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return {
      src: `${process.env.REACT_APP_CLOUDFRONT}/merchandise/${storeId}/${merchandiseId}/${value}`,
    }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

const formatFiles = (data, merchandiseId, storeId) => {
  return data.map((item) => {
    if (item.rawFile) {
      return {
        ...item,
        name: item.title,
        src: item.src,
      }
    } else {
      return {
        ...item,
        name: item.name,
        src: `${process.env.REACT_APP_CLOUDFRONT}/merchandise/${storeId}/${merchandiseId}/${item.name}`,
      }
    }
  })
}

const MerchandiseImage = ({ merchandiseId }) => {
  const { storeId } = useRecordContext()

  return (
    <ImageInput
      source='cover'
      label='商品大圖'
      accept='image/*'
      validate={required()}
      format={(value) => formatImg(value, merchandiseId, storeId)}
    >
      <ImageField source='src' title='title' />
    </ImageInput>
  )
}

const MerchandiseFile = ({ merchandiseId }) => {
  const { storeId } = useRecordContext()

  return (
    <FileInput
      source='media'
      label='商品輪播圖'
      validate={[
        required(),
        validateFileType,
        validateMaxAmount,
        validateFileSize,
        validate3DFile,
        validateFileSameName,
      ]}
      multiple
      format={(value) => formatFiles(value, merchandiseId, storeId)}
    >
      <FileField source='src' title='name' src='src' />
    </FileInput>
  )
}

const MySaveButton = () => {
  const notify = useNotify()
  const redirect = useRedirect()

  return (
    <SaveButton
      label='Save'
      type='button'
      mutationOptions={{
        onSuccess: () => {
          notify('資料更新中...', { type: 'info', autoHideDuration: 2000, undoable: true })
          redirect('list', 'merchandise')
        },
      }}
    />
  )
}

const MyToolBar = () => {
  return (
    <Toolbar>
      <MySaveButton />
    </Toolbar>
  )
}

const MerchandiseEdit = () => {
  const { id } = useParams()

  return (
    <Edit>
      <TabbedForm toolbar={<MyToolBar />}>
        <FormTab label='Required'>
          <TextInput label='商品名稱' source='merchandiseName' validate={required()} />
          <TextInput multiline label='商品說明' source='description' />
          <TextInput label='商品連結文案' source='linkLabel' />
          <TextInput label='商品連結' source='link' />
          {/* <TextInput label='Price' source='price' validate={required()} /> */}
          <MerchandiseImage merchandiseId={id} />
          <MerchandiseFile merchandiseId={id} />
        </FormTab>
        <FormTab label='Recommand'>
          {/* <AutocompleteArrayInput
          source='purpose'
          choices={[
            { id: 'food', name: '食' },
            { id: 'clothes', name: '衣' },
            { id: 'stay', name: '住' },
            { id: 'goOut', name: '行' },
            { id: 'educate', name: '育' },
            { id: 'fun', name: '樂' },
            { id: 'groceries', name: '雜貨' },
          ]}
        />
        <AutocompleteArrayInput
          source='target'
          choices={[
            { id: 'baby', name: '嬰兒' },
            { id: 'child', name: '孩童' },
            { id: 'teenager', name: '青少年' },
            { id: 'adult', name: '成年' },
            { id: 'old', name: '老人' },
          ]}
        />
        <AutocompleteArrayInput
          source='price'
          choices={[
            { id: 'cheap', name: '$(0~1000)' },
            { id: 'normal', name: '$$(1000~5000)' },
            { id: 'expensive', name: '$$$(5000~100000)' },
            { id: 'veryExpensive', name: '$$$$(100000~)' },
          ]}
        /> */}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default MerchandiseEdit
