import { createContext } from 'react'
import io from 'socket.io-client'

export const SocketContext = createContext()
export const MessageListContext = createContext()
export const ConnectedClientListContext = createContext()
export const PeerContext = createContext()

export const socket = io(process.env.REACT_APP_API_DOMAIN, {
  autoConnect: false,
  path: '/api/1.0/socket.io/',
})
