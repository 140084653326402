import PortalUserList from './list'
import PortalUserCreate from './create'
import PortalUserEdit from './edit'

const portalUser = {
  list: PortalUserList,
  create: PortalUserCreate,
  edit: PortalUserEdit,
}
export default portalUser
