import MarqueeCreate from './create'
import MarqueeEdit from './edit'
import MarqueeList from './list'

const marquee = {
  list: MarqueeList,
  create: MarqueeCreate,
  edit: MarqueeEdit,
}
export default marquee
