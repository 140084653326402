import * as React from 'react'
import { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BulkDeleteButton,
  BulkExportButton,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { UserContext } from '../../context/user'

const PortalUserList = () => {
  const { user } = React.useContext(UserContext)
  return (
    <List hasCreate={user?.username === 'fatpoJT957'} title='使用者'>
      <Datagrid
        rowClick='edit'
        bulkActionButtons={
          <Fragment>
            <BulkExportButton />
            {user?.username === 'fatpoJT957' && <BulkDeleteButton />}
          </Fragment>
        }
      >
        <TextField source='username' label='帳號' />
        <TextField source='owner' label='使用者名稱' />
        <TextField source='tel' label='電話號碼' />
        <ReferenceField source='managedStoreId' reference='store' label='管理商店名'>
          <TextField source='storeName' />
        </ReferenceField>
        <EmailField source='email' />
        <FunctionField
          sortBy='removedTime'
          label='停用狀態'
          render={(record) => {
            if (record.removedTime) {
              return '停用中'
            }
          }}
        />
      </Datagrid>
    </List>
  )
}

export default PortalUserList
