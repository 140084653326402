import { List, Datagrid, TextField, TextInput, useRecordContext } from 'react-admin'
import { Stack, Chip } from '@mui/material'

const PriceSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.price.map((priceType) => (
        <Chip size='small' key={priceType} label={priceType} color='primary' />
      ))}
    </Stack>
  )
}

const PurposeSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.purpose.map((purposeType) => (
        <Chip size='small' key={purposeType} label={purposeType} color='success' />
      ))}
    </Stack>
  )
}

const TargetSegments = () => {
  const record = useRecordContext()
  return (
    <Stack direction='row' gap={1} flexWrap='wrap'>
      {record.target.map((targetType) => (
        <Chip size='small' key={targetType} label={targetType} color='success' variant='outlined' />
      ))}
    </Stack>
  )
}

const StoreLevelSegment = () => {
  const record = useRecordContext()
  const storeLevleList = [
    { id: 1, name: '初版店面' },
    { id: 2, name: '市集店面' },
    { id: 3, name: '一般店面' },
    { id: 4, name: '精品店面' },
    { id: 5, name: '客製化店面' },
    { id: 6, name: '電視牆' },
  ]
  return <div>{storeLevleList.find((item) => item.id === record.storeLevel).name}</div>
}

const storeFilters = [
  <TextInput label='Search' source='storeName' alwaysOn />,
  <TextInput label='Description' source='description' defaultValue='鞋' />,
  <TextInput label='Price' source='price' />,
]

const StoreList = () => {
  return (
    <>
      <List filters={storeFilters} title='商店'>
        <Datagrid rowClick='edit'>
          <TextField source='storeName' label='商店名' />
          <TextField source='description' label='商店簡介' />
          <TextField source='storeCode' label='地點代碼' />
          <StoreLevelSegment source='storeLevel' label='商店種類' />
          <PriceSegments source='price' label='價格' />
          <PurposeSegments source='purpose' label='標籤' />
          <TargetSegments source='target' label='客群' />
        </Datagrid>
      </List>
    </>
  )
}

export default StoreList
