import * as React from 'react'
import { MenuItemLink } from 'react-admin'
import BookmarkIcon from '@mui/icons-material/BookmarkBorder'
import SettingsIcon from '@mui/icons-material/Settings'
import LabelIcon from '@mui/icons-material/Label'
import LandscapeIcon from '@mui/icons-material/Landscape'
import { UserContext } from '../context/user'
import TrainIcon from '@mui/icons-material/Train'
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import ArticleIcon from '@mui/icons-material/Article'

const mallMenuItems = [
  { name: 'portalUser', text: '使用者', icon: <BookmarkIcon /> },
  // { name: 'user', text: 'User', icon: <ChatBubbleIcon /> },
  { name: 'store', text: '商店', icon: <LabelIcon /> },
  // { name: 'shopCart', text: 'ShopCart', icon: <SettingsIcon /> },
  { name: 'merchandise', text: '商品', icon: <SettingsIcon /> },
  // { name: 'textchat', text: 'Text Chat', icon: <SettingsIcon /> },
  // { name: 'videochat', text: 'Video Chat', icon: <SettingsIcon /> },
  { name: 'landscaping', text: '景觀', icon: <LandscapeIcon /> },
  { name: 'mrt', text: '捷運站', icon: <TrainIcon /> },
  { name: 'marquee', text: '跑馬燈', icon: <ArticleIcon /> },
]

const bookStoreMenuItems = [{ name: 'book', text: '書本', icon: <ImportContactsIcon /> }]

const MyMenu = ({ onMenuClick }) => {
  const { user } = React.useContext(UserContext)
  const [mallOpen, setMallOpen] = React.useState(true)
  const [bookStoreOpen, setBookStoreOpen] = React.useState(true)

  return (
    <List component='nav' style={{ padding: 0, width: 240 }}>
      <ListItemButton
        onClick={() => {
          setMallOpen(!mallOpen)
        }}
      >
        <ListItemIcon>
          <StoreMallDirectoryIcon />
        </ListItemIcon>
        <ListItemText primary='Mall' />
        {mallOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={mallOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {mallMenuItems.map((item) => {
            if (item.name === 'landscaping' || item.name === 'mrt' || item.name === 'marquee') {
              if (user?.username === 'fatpoJT957') {
                return (
                  <MenuItemLink
                    style={{ paddingLeft: 40 }}
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                  />
                )
              } else {
                return null
              }
            }
            return (
              <MenuItemLink
                style={{ paddingLeft: 40 }}
                key={item.name}
                to={`/${item.name}`}
                primaryText={item.text}
                leftIcon={item.icon}
                onClick={onMenuClick}
              />
            )
          })}
        </List>
      </Collapse>
      {user?.username === 'fatpoJT957' && (
        <>
          <ListItemButton
            onClick={() => {
              setBookStoreOpen(!bookStoreOpen)
            }}
          >
            <ListItemIcon>
              <StoreMallDirectoryIcon />
            </ListItemIcon>
            <ListItemText primary='Book Store' />
            {bookStoreOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={bookStoreOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {bookStoreMenuItems.map((item) => {
                return (
                  <MenuItemLink
                    style={{ paddingLeft: 40 }}
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                  />
                )
              })}
            </List>
          </Collapse>
        </>
      )}
    </List>
  )
}

export default MyMenu
