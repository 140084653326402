import { Datagrid, List, NumberField, TextField } from 'react-admin'

const BookList = () => {
  return (
    <>
      <List title='商店'>
        <Datagrid rowClick='edit'>
          <TextField source='bookName' label='書名' />
          <TextField source='author' label='作者' />
          <TextField label='出版商' source='publisher' />
          <NumberField label='X座標' source='positionX' />
          <NumberField label='Y座標' source='positionY' />
          <NumberField label='Z座標' source='positionZ' />
          <TextField label='供應商' source='supplier' />
        </Datagrid>
      </List>
    </>
  )
}

export default BookList
