import StoreList from './list'
import StoreCreate from './create'
import StoreEdit from './edit'

const Store = {
  list: StoreList,
  create: StoreCreate,
  edit: StoreEdit,
}
export default Store
